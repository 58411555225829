import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setRegions,
  setPaymentWay,
  setProvinces,
  setMunicipalities,
} from "../store/slices/FormSlice";
import query from "./APIServer";

export const useServerForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { regions, paymentWay } = useAppSelector((state) => state.form);

  const findAllRegions = async () => {
    if (!regions || regions.length === 0) {
      setIsLoading(true);
      try {
        const response: any = await query.get(`/marketplace/shipping/region?all_data=true`);
        // setData(response.data.items);
        dispatch(setRegions(response.data.items));
      } catch (error: any) {
        console.log("Error fetching data:", error);
        setError(error);
      }
      setIsLoading(false);
    }
  };
  const findAllCountriesProvincesAndMunicipalities = async () => {
    setIsLoading(true);

    const queries = [
      query.get( `/public/provinces?countryId=54`),
      query.get( `/public/municipalities?all_data=true`),
    ];
    try {
      Promise.all(queries).then((resp) => {
        dispatch(setProvinces(resp[0].data.items));
        dispatch(setMunicipalities(resp[1].data.items));
      });
    } catch (error: any) {
      console.log("Error fetching data:", error);
      setError(error);
    }
    setIsLoading(false);
  };

  const getPaymentWays = async () => {
    if (!paymentWay || paymentWay.length === 0) {
      setIsLoading(true);
      try {
        const response: any = await query.get(`/marketplace/paymentgateway`);
        // setData(response.data.items);
        dispatch(setPaymentWay(response.data));
      } catch (error: any) {
        console.log("Error fetching data:", error);
        setError(error);
      }
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    findAllRegions,
    getPaymentWays,
    findAllCountriesProvincesAndMunicipalities,
  };
};
